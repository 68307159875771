export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPost: (state) => state.currentPost,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  menuOpened: (state) => state.menuOpened,
  isZoomed: (state) => state.zoomed,
};
