<template>
  <div class="block--team">
    <div class="heading t-center">
      <Title
        v-if="data.attrs.data.fullname"
        :data="{ value: data.attrs.data.fullname, tag: 'h3', size: 's' }"
      />
      <Title
        v-if="data.attrs.data.role"
        :data="{ value: data.attrs.data.role, tag: 'h3', size: 's' }"
      />
    </div>
    <Spacer
      v-if="data.attrs.data.bio"
      :x="{ default: 's', m: 'xxxl' }"
    >
      <Richtext :data="{ value: data.attrs.data.bio }" />
    </Spacer>
    <Spacer
      v-if="data.attrs.data.email"
      :x="{ default: 's', m: 'xxxl' }"
    >
      <Cta
        :data="{ url: `mailto:${data.attrs.data.email}`, title: data.attrs.data.email, target: '_blank' }"
        theme="inline"
      />
    </Spacer>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Team',
  components: {
    Title,
    Richtext,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block--team {
  display: grid;
  grid-row-gap: var(--spacer-s);

  margin-bottom: var(--spacer-xl);
  margin-top: var(--spacer-xl);
}
</style>
