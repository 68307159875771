<template>
  <component
    :is="mergeProps.tag"
    :class="['title', `typo--${mergeProps.size}`]"
    v-html="mergeProps.value"
  />
</template>

<script>
import fitty from 'fitty';

export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fit: null,
    };
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        tag: 'h2',
        size: 'l',
      };

      Object.keys(this.data).forEach((key) => this.data[key] === undefined && delete this.data[key]);
      return { ...defaultProps, ...this.data };
    },
  },
  mounted() {
    if (this.mergeProps.size === 'xxl') {
      this.fit = fitty(this.$el, {
        multiLine: true,
      });
    }
  },
  beforeDestroy() {
    if (this.fit) {
      // this.fit.unsubscribe();
    }
  },
};
</script>

<style lang="scss" scoped>
.typo--xxl {
  display: inline-block;
  white-space: nowrap;
}
</style>
