var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('Flex',{staticClass:"nav-bar",attrs:{"align":"center","justify":"space-between"}},[_c('transition',{attrs:{"name":"flexed"}},[(_vm.$route.name !== 'Index' || _vm.$mq.isMobile)?_c('Flex',{staticClass:"flexed",attrs:{"align":"center"}},[_c('transition',{attrs:{"name":"flexed-back"}},[(_vm.$mq.isMobile && (_vm.$route.meta.type === 'lehre' || _vm.$route.meta.type === 'research' || _vm.$route.meta.type === 'posts'))?_c('router-link',{staticClass:"flexed-back",attrs:{"to":_vm.archive}},[_c('Spacer',{attrs:{"all":"s"}},[_c('div',{staticClass:"back"})])],1):_vm._e()],1),(_vm.$route.name !== 'Index' || _vm.$mq.isMobile)?_c('router-link',{attrs:{"to":"/"}},[_c('Spacer',{attrs:{"all":"s"}},[_c('div',{staticClass:"index"})])],1):_vm._e()],1):_vm._e()],1),(!_vm.$mq.isMobile)?[_c('Flex',{attrs:{"justify":"space-between","align":"center"}},_vm._l((_vm.navigation),function(item){return _c('div',{key:item.id,class:['item', item.url === '#!' ? 'item--submenu' : false]},[_c('Cta',{class:['item-cta', item.url === '#!' && (_vm.$route.meta.type === 'posts' || _vm.$route.meta.type === 'lehre') ? 'item-cta--active' : false],attrs:{"data":{
              url: item.url,
              title: item.content,
              target: item.target,
            }}}),(item.children && item.children.length > 0)?_c('div',{staticClass:"submenu"},_vm._l((item.children),function(subitem){return _c('Cta',{key:subitem.id,staticClass:"submenu-item",attrs:{"data":{
                url: subitem.url,
                title: subitem.content,
                target: subitem.target,
              }}})}),1):_vm._e()],1)}),0)]:_vm._e()],2),_c('transition',{attrs:{"css":false},on:{"enter":_vm.enterMenu,"leave":_vm.leaveMenu}},[(_vm.$mq.isMobile && _vm.menuOpened)?[_c('div',{staticClass:"nav-mobile"},[_c('div'),_c('div',{staticClass:"nav-menu"},_vm._l((_vm.navigation),function(item){return _c('div',{key:item.id,class:['item', item.url === '#!' ? 'item--submenu' : false, _vm.submenu === item.id ? 'item--submenu-active' : false],on:{"click":function($event){item.url !== '#!' ? _vm.setSubmenu(null) : false}}},[_c('Cta',{staticClass:"item-cta",attrs:{"data":{
                url: item.url,
                title: item.content,
                target: item.target,
                size: 'cta-big',
                fn: item.url === '#!' ? function () { _vm.setSubmenu(item.id) } : false
              }}}),(item.children && item.children.length > 0)?_c('div',{class:['submenu']},_vm._l((item.children),function(subitem){return _c('Cta',{key:subitem.id,staticClass:"submenu-item",attrs:{"data":{
                  url: subitem.url,
                  title: subitem.content,
                  target: subitem.target,
                }}})}),1):_vm._e()],1)}),0),_c('div',{staticClass:"nav-footer"},[(_vm.options.links.instagram && _vm.options.links.instagram !== '')?_c('Cta',{attrs:{"data":{
              url: _vm.options.links.instagram,
              title: 'Instagram',
              target: '_blank',
            }}}):_vm._e(),(_vm.options.links.newsletter && _vm.options.links.newsletter !== '')?_c('Cta',{attrs:{"data":{
              url: _vm.options.links.newsletter,
              title: 'Newsletter',
              target: '_blank',
            }}}):_vm._e()],1)])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }