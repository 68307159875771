var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:[
    'page',
    _vm.post && _vm.post.acf.layout
      ? ("page--" + (_vm.post.acf.layout))
      : _vm.$route.params.type !== 'page'
        ? 'page--single'
        : false ]},[_c('div',{staticClass:"sheet sheet--fixed"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"t-center typo--xxl-container"},[(_vm.post.acf.layout === 'img' && _vm.post.gds_featured_image)?_c('Figure',{attrs:{"data":Object.assign({}, _vm.post.gds_featured_image, {ratio: true})}}):(_vm.post.acf.layout === 'contact')?_c('Spacer',{staticClass:"g",attrs:{"all":"s"}},[_c('inline-svg',{attrs:{"src":require('@/assets/svg/g.svg')}})],1):_c('Title',{attrs:{"data":{ value: _vm.post.title.rendered, size: 'xxl' }}})],1)])]),_c('div',{staticClass:"sheet sheet--scroll"},[_c('Gutenberg',{staticClass:"container",attrs:{"data":_vm.post.gds_blocks}}),(_vm.post.acf.layout === 'contact' && _vm.post.slug === 'contact')?_c('Flex',{staticClass:"contact-links",attrs:{"justify":"space-between"}},[(
          _vm.options.links.privacy_policy && _vm.options.links.privacy_policy !== ''
        )?_c('Cta',{attrs:{"data":{
          title: 'Privacy policy',
          url: _vm.options.links.privacy_policy,
          target: '_blank',
          size: 'cta-small',
        }}}):_vm._e(),_c('Cta',{attrs:{"data":{
          title: 'Website by Giga',
          url: 'https://www.gigadesignstudio.com',
          target: '_blank',
          size: 'cta-small',
        }}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }