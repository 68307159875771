<template>
  <Spacer
    class="block--publication t-center"
  >
    <Figure :data="{ ...data.attrs.data.cover, ratio: true }" />
    <Richtext :data="{ value: data.attrs.data.content.text }" />
    <Cta
      :data="data.attrs.data.content.link"
      theme="inline"
    />
  </Spacer>
</template>

<script>
import Figure from '@/components/media/figure';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Publication',
  components: {
    Figure,
    Richtext,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block--publication {
  .picture {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    margin: 0 auto var(--spacer-s);

    max-width: 116px;

    @include mq(m) {
      max-width: 232px;
    }
  }

  @include mq(m) {
    padding-left: var(--spacer-xxxl);
    padding-right: var(--spacer-xxxl);
  }

  margin-bottom: var(--spacer-xl);
  margin-top: var(--spacer-xl);
}
</style>
