<template>
  <Spacer
    :id="data.attrs.anchor"
    :x="{ default: data.blockName === 'core/paragraph' || data.blockName === 'core/list' || data.blockName === 'acf/link' ? 's' : undefined, m: 'xxxl' }"
    :class="[
      data.attrs.align === 'center' || data.blockName === 'core/heading' && data.attrs.level === 3 ? 't-center' : false,
      `block--${data.blockName.split('/')[1]}`
    ]"
  >
    <Title
      v-if="data.blockName === 'core/heading'"
      :data="{
        value: data.innerHTML,
        tag: data.attrs.level ? `h${data.attrs.level}` : undefined,
        size: data.attrs.level === 3 ? 's' : 'xl',
      }"
    />

    <RichText
      v-if="data.blockName === 'core/paragraph' || data.blockName === 'core/list'"
      :data="{
        value: data.innerHTML,
      }"
    />

    <Cta
      v-if="data.blockName === 'acf/link'"
      :data="data.attrs.data.link"
      theme="inline"
    />
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  &--heading {
    margin-top: var(--spacer-s);
    margin-bottom: var(--spacer-s);

    & + .block--heading {
      margin-top: 0;
    }
  }

  &--paragraph {
    margin-top: var(--spacer-s);
    margin-bottom: var(--spacer-s);

    & + .block--paragraph {
      margin-top: 0;
    }
  }

  &--list {
    margin-top: var(--spacer-s);
    margin-bottom: var(--spacer-s);
  }

  &--link {
    margin-top: var(--spacer-s);
    margin-bottom: var(--spacer-s);
  }
}
</style>
