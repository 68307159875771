<template>
  <div
    :class="[
      'cta',
      `cta-theme--${theme}`,
      data.size ? `typo--${data.size}` : theme === 'inline' ? 'typo--s' : 'typo--cta',
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button
        @click="!disabled ? data.fn() : null"
        v-html="data.title"
      />
    </template>
    <template v-else-if="modal">
      <button
        @click="openModal"
        v-html="data.title"
      />
    </template>
    <template v-else-if="hash">
      <button
        @click="goTo"
        v-html="data.title"
      />
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
        v-html="data.title"
      />
    </template>
    <template v-else>
      <router-link
        :to="url"
        v-html="data.title"
      />
    </template>
  </div>
</template>

<script>
// const baseUrl = process.env.VUE_APP_PROXY_TARGET ? `${process.env.VUE_APP_PROXY_TARGET}${process.env.VUE_APP_BASE_URL}` : __VUE_WORDPRESS__.routing.url;
// eslint-disable-next-line no-undef
const baseUrl = __VUE_WORDPRESS__.routing.url;

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
        && typeof this.data.url === 'string'
        && this.data.url.startsWith(baseUrl)
      ) {
        return this.$relativeUrl(this.data.url);
      }
      return this.data.url;
    },
    external() {
      return (
        (this.url && typeof this.url === 'string' && !this.data.url.startsWith(baseUrl)) || this.data.target === '_blank');
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
        && typeof this.url === 'string'
        && this.url.startsWith('#modal-')
      );
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        console.log('to do', hash);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;
}

.cta-disabled {
  // cursor: progress;
  opacity: 0.5;

  * {
    // cursor: progress !important;
    pointer-events: none !important;
  }
}

button {
  font-family: inherit;
  text-transform: inherit;
  font-size: inherit;
}

.cta {
  a,
  button {
    user-select: none;
    display: inline-block;
    line-height: inherit;
    padding: var(--spacer-s);
  }

  &.typo--cta-big {
    a, button {
      line-height: inherit;
      padding: 0;
    }
  }

  &-theme {
    &--inherit {
      a,
      button {
        color: var(--red);
        padding: 0;
        text-transform: none;
      }
    }
    &--inline {
      a,
      button {
        color: var(--red);
        padding: 0;

        &::before {
          content: '→';
          display: inline-block;
          margin-right: var(--spacer-xxs);
        }
      }
    }
  }
}
</style>
