<template>
  <Spacer
    class="block"
    top="xxl"
  />
</template>

<script>
export default {
  name: 'Break',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
