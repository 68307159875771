<template>
  <main class="page page--archive">
    <div class="sheet sheet--fixed">
      <div class="container">
        <div class="t-center typo--xxl-container">
          <Title :data="{ value: title, size: 'xxl' }" />
        </div>
      </div>
    </div>
    <div class="sheet sheet--scroll">
      <Grid
        class="container"
        :row-gap="$mq.isMobile ? 'xl' : 'xxxl'"
        grid="1"
      >
        <Thumb
          v-for="(thumb, key) in archive"
          :key="key"
          :data="thumb"
        />
      </Grid>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '@/components/typo/title';
import Thumb from '@/components/thumb';

export default {
  name: 'Archive',
  components: {
    Title,
    Thumb,
  },
  data() {
    return {
      title: null,
      archive: null,
    };
  },
  computed: {
    ...mapGetters(['postType', 'archiveByType']),
  },
  created() {
    const { slug } = this.$route.params;
    this.title = this.postType(slug).label;
    this.archive = this.archiveByType(slug);
  },
};
</script>

<style lang="scss" scoped>

</style>
