<template>
  <nav>
    <Flex
      align="center"
      class="nav-bar"
      justify="space-between"
    >
      <transition name="flexed">
        <Flex
          v-if="$route.name !== 'Index' || $mq.isMobile"
          align="center"
          class="flexed"
        >
          <transition name="flexed-back">
            <router-link
              v-if="$mq.isMobile && ($route.meta.type === 'lehre' || $route.meta.type === 'research' || $route.meta.type === 'posts')"
              :to="archive"
              class="flexed-back"
            >
              <Spacer all="s">
                <div class="back" />
              </Spacer>
            </router-link>
          </transition>
          <router-link
            v-if="$route.name !== 'Index' || $mq.isMobile"
            to="/"
          >
            <Spacer all="s">
              <div class="index" />
            </Spacer>
          </router-link>
        </Flex>
      </transition>
      <template v-if="!$mq.isMobile">
        <Flex
          justify="space-between"
          align="center"
        >
          <div
            v-for="item in navigation"
            :key="item.id"
            :class="['item', item.url === '#!' ? 'item--submenu' : false]"
          >
            <Cta
              :class="['item-cta', item.url === '#!' && ($route.meta.type === 'posts' || $route.meta.type === 'lehre') ? 'item-cta--active' : false]"
              :data="{
                url: item.url,
                title: item.content,
                target: item.target,
              }"
            />

            <div
              v-if="item.children && item.children.length > 0"
              class="submenu"
            >
              <Cta
                v-for="subitem in item.children"
                :key="subitem.id"
                class="submenu-item"
                :data="{
                  url: subitem.url,
                  title: subitem.content,
                  target: subitem.target,
                }"
              />
            </div>
          </div>
        </Flex>
      </template>
    </Flex>
    <transition
      :css="false"
      @enter="enterMenu"
      @leave="leaveMenu"
    >
      <template v-if="$mq.isMobile && menuOpened">
        <div class="nav-mobile">
          <div />
          <div class="nav-menu">
            <div
              v-for="item in navigation"
              :key="item.id"
              :class="['item', item.url === '#!' ? 'item--submenu' : false, submenu === item.id ? 'item--submenu-active' : false]"
              @click="item.url !== '#!' ? setSubmenu(null) : false"
            >
              <Cta
                :data="{
                  url: item.url,
                  title: item.content,
                  target: item.target,
                  size: 'cta-big',
                  fn: item.url === '#!' ? () => { setSubmenu(item.id) } : false
                }"
                class="item-cta"
              />

              <div
                v-if="item.children && item.children.length > 0"
                :class="['submenu']"
              >
                <Cta
                  v-for="subitem in item.children"
                  :key="subitem.id"
                  class="submenu-item"
                  :data="{
                    url: subitem.url,
                    title: subitem.content,
                    target: subitem.target,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="nav-footer">
            <Cta
              v-if="options.links.instagram && options.links.instagram !== ''"
              :data="{
                url: options.links.instagram,
                title: 'Instagram',
                target: '_blank',
              }"
            />

            <Cta
              v-if="options.links.newsletter && options.links.newsletter !== ''"
              :data="{
                url: options.links.newsletter,
                title: 'Newsletter',
                target: '_blank',
              }"
            />
          </div>
        </div>
      </template>
    </transition>
  </nav>
</template>

<script>
import { timeline } from 'motion';
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  data() {
    return {
      submenu: null,
    };
  },
  computed: {
    ...mapGetters(['menu', 'postType', 'menuOpened', 'options', 'transition']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
    archive() {
      if (this.$route.meta.type === 'posts') {
        return `/lehre/${this.$route.params.category}`;
      }
      return `/${this.postType(this.$route.meta.type).has_archive}`;
    },
  },
  watch: {
    menuOpened(val) {
      if (!val) {
        this.submenu = null;
      } else if (val && (this.$route.meta.type === 'posts' || this.$route.meta.type === 'lehre')) {
        this.submenu = this.navigation.find((item) => item.content === 'Lehre').id;
      }
    },
  },
  methods: {
    setSubmenu(id) {
      if (id && id === this.submenu) {
        this.submenu = null;
      } else if (id) {
        this.submenu = id;
      } else {
        this.submenu = null;
      }
    },
    enterMenu(el, done) {
      const nav = el;
      nav.style.transform = 'translate3d(100%, 0, 0)';

      const sequence = [
        [nav, {
          transform: 'translate3d(0%, 0px, 0)',
        }],
      ];

      timeline(sequence, {
        duration: 0.5,
        easing: [0.19, 1, 0.22, 1],
      }).finished.then(() => {
        done();
      });
    },
    leaveMenu(el, done) {
      if (this.transition) {
        done();
        return;
      }
      const nav = el;
      const sequence = [
        [nav, {
          transform: 'translate3d(100%, 0px, 0)',
        }],
      ];

      timeline(sequence, {
        duration: 0.5,
        easing: [0.19, 1, 0.22, 1],
      }).finished.then(() => {
        done();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  @include mq(m) {
    width: 50vw;
  }

  ::v-deep .router-link-active {
    color: var(--red);
  }
}

.index {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--red);
}

.back {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 12px 6px 0;
  border-color: transparent currentColor transparent transparent;
}

.nav-bar {
  position: relative;
  z-index: 15;

  @include mq(m) {
    & > * {
      flex-grow: 4;
    }
  }
}

.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column wrap;
  background: var(--white);

  .nav-menu {
    display: grid;
    align-items: center;
    text-align: center;
    row-gap: var(--spacer-s);
  }

  .nav-footer {
    width: 100%;
    color: var(--red);
    display: flex;
    justify-content: space-between;
  }
}

.flexed {
  @include mq(m) {
    flex-grow: 1;
    transition: flex-grow 0.5s $ease-custom;

    & > * {
      position: absolute;
    }
  }
}

.flexed-back {
  flex-basis: 36px;
  width: 36px;
  height: 36px;
  transition: flex-basis 0.25s $ease-custom, width 0.25s $ease-custom;

  & > * {
    position: absolute;
  }
}

.flexed-enter, .flexed-leave-to {
  flex-grow: 0.0000001;
  opacity: 0;
}

.flexed-back-enter, .flexed-back-leave-to {
  width: 0;
  flex-basis: 0;
  opacity: 0;
}

.item {
  position: relative;

  .cta {
    transition: color 0.25s $ease-custom;

    @include mq($and: $hover) {
      &:hover {
        color: var(--red);
      }
    }
  }

  .submenu {
    transition: all 0.25s $ease-custom;
    // padding-top: 6px;
    height: 0;
    visibility: hidden;
    overflow: hidden;

    @include mq(m) {
      opacity: 0;
      height: auto;
      position: absolute;
      top: calc(100% - 6px);
      padding-top: 0;
    }

    &-item {
      white-space: nowrap;

      ::v-deep a, button {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }

  &--submenu {
    .item-cta {
      @include mq(m) {
        pointer-events: none;
      }

      &--active {
        color: var(--red);
      }
    }
    @include mq(m, $and: $hover) {
      &:hover {
        .item-cta {
          color: var(--red);
        }
        .submenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &-active {
      .item-cta {
        color: var(--red);
      }
      .submenu {
        margin-top: 6px;
        visibility: visible;
        height: auto;
      }
    }
  }
}
</style>
