<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Figure
      v-if="data.gds_featured_image"
      :data="{ ...data.gds_featured_image, ratio: true }"
    />
    <Spacer
      x="s"
      top="s"
    >
      <Title :data="{ value: data.title.rendered, size: 'xl' }" />
    </Spacer>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  display: grid;
  // padding: 0 var(--spacer-s);
}
</style>
