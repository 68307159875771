<template>
  <Spacer
    :x="{ default: 's', m: 'xxxl' }"
    class="block--lectures"
  >
    <div
      v-for="lecture in data.attrs.data.lectures"
      :key="lecture.id"
      class="lecture"
    >
      <router-link
        class="lecture-link"
        :to="$relativeUrl(lecture.link)"
      >
        <time v-html="lecture.acf.date" /> <Title :data="{ value: lecture.title.rendered }" />
      </router-link>
      <Cta
        v-if="lecture.acf.download_lecture"
        :data="{ title: 'Download lecture', url: lecture.acf.download_lecture, target: '_blank' }"
        theme="inline"
      />
      <Cta
        v-if="lecture.acf.download_notes"
        :data="{ title: 'Download notes', url: lecture.acf.download_notes, target: '_blank' }"
        theme="inline"
      />
    </div>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'Lectures',
  components: {
    Title,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block--lectures {
  margin-top: var(--spacer-s);
  margin-bottom: var(--spacer-s);
}

.lecture {
  @extend %typo--s;

  &-link {
    display: flex;

    time {
      display: block;
      margin-right: var(--spacer-xs);
    }
  }
  .title {
    color: var(--red);
  }
}
</style>
