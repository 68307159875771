<template>
  <div class="block--posts">
    <Grid
      :row-gap="$mq.isMobile ? 'xl' : 'xxxl'"
      grid="1"
    >
      <Thumb
        v-for="(thumb, key) in archive"
        :key="key"
        :data="thumb"
      />
    </Grid>
  </div>
</template>

<script>
import Thumb from '@/components/thumb';

export default {
  name: 'Posts',
  components: {
    Thumb,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    archive() {
      return this.data.attrs.data.posts;
    },
  },
};
</script>

<style lang="scss" scoped>
.block--posts {
  display: grid;
  grid-row-gap: var(--spacer-s);

  margin-bottom: var(--spacer-xl);
  margin-top: var(--spacer-xl);

  .container {
    padding: 0;
  }
}
</style>
