export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  TOGGLE_MENU(state) {
    state.menuOpened = !state.menuOpened;
  },
  IS_ZOOMED(state, value) {
    state.zoomed = value;
  },
};
