var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',{class:[
    _vm.data.attrs.align === 'center' || _vm.data.blockName === 'core/heading' && _vm.data.attrs.level === 3 ? 't-center' : false,
    ("block--" + (_vm.data.blockName.split('/')[1]))
  ],attrs:{"id":_vm.data.attrs.anchor,"x":{ default: _vm.data.blockName === 'core/paragraph' || _vm.data.blockName === 'core/list' || _vm.data.blockName === 'acf/link' ? 's' : undefined, m: 'xxxl' }}},[(_vm.data.blockName === 'core/heading')?_c('Title',{attrs:{"data":{
      value: _vm.data.innerHTML,
      tag: _vm.data.attrs.level ? ("h" + (_vm.data.attrs.level)) : undefined,
      size: _vm.data.attrs.level === 3 ? 's' : 'xl',
    }}}):_vm._e(),(_vm.data.blockName === 'core/paragraph' || _vm.data.blockName === 'core/list')?_c('RichText',{attrs:{"data":{
      value: _vm.data.innerHTML,
    }}}):_vm._e(),(_vm.data.blockName === 'acf/link')?_c('Cta',{attrs:{"data":_vm.data.attrs.data.link,"theme":"inline"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }