<template>
  <div id="app">
    <div
      v-if="$mq.isMobile && $route.name"
      class="menu-btn"
    >
      <Cta
        :data="{
          fn: toggleMenu,
          title: menuOpened ? 'Close' : 'Menu',
        }"
      />
    </div>
    <AppHeader />
    <transition
      :css="false"
      mode="in-out"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapGetters } from 'vuex';
import { timeline } from 'motion';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import Cookie from '@/components/ui/cookie';
import Cta from '@/components/typo/cta';

export default {
  name: 'App',
  components: {
    AppHeader,
    Cookie,
    Cta,
  },
  mixins: [lazyload],
  data() {
    return {
      top: 0,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'menuOpened']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized');
      Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
      this.$mq.vh();
    }, 400));
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      if (this.$store.state.route.from && this.$store.state.route.from.name) {
        disableBodyScroll(el);
      }

      const fromHome = this.$store.state.route.from && this.$store.state.route.from.name && this.$store.state.route.from.name.includes('Index');

      if (!fromHome && !(this.menuOpened && this.$mq.isMobile)) {
        el.style.position = 'absolute';
        el.style.top = '0px';
        el.style.left = '0px';
        done();
      } else {
        this.$el.classList.add('is-trans');
        const to = this.$route && this.$store.state.wp.pages.default[this.$route.params.slug] ? this.$store.state.wp.pages.default[this.$route.params.slug].acf.layout : false;
        const fixed = to === 'contact' ? 1 : 0;
        const scroll = to === 'contact' ? 0 : 1;

        el.style.position = 'absolute';
        el.style.top = '0px';
        el.style.left = '0px';
        el.style.zIndex = 5;

        const { hash } = this.$route;
        if (hash) {
          const { top } = this.$el.querySelector(hash).getBoundingClientRect();
          this.top = top;
        }
        window.scroll(0, 0);

        el.style.top = this.top ? `${this.top * -1}px` : '0px';

        if (this.$route.name.includes('Index')) {
          el.style.width = '100%';
        }

        if (!this.$mq.isMobile) {
          el.children[fixed].style.transform = 'translate3d(-100%, 0, 0)';
          el.children[scroll].style.transform = 'translate3d(100%, 0, 0)';
        } else if (!this.menuOpened) {
          el.style.transform = 'translate3d(-100%, 0, 0)';
        }

        const nav = this.$el.querySelector('.nav-mobile');

        const sequence = !this.$mq.isMobile ? [
          [
            el.children[fixed], {
              transform: 'translate3d(0, 0, 0)',
            }],
          [
            el.children[scroll], {
              transform: 'translate3d(0, 0px, 0)',
            }, { at: 0 }],
        ] : nav && this.menuOpened ? [[
          nav, {
            transform: 'translate3d(100%, 0px, 0)',
          },
        ]] : [
          [
            el, {
              transform: 'translate3d(0, 0, 0)',
            },
          ],
        ];

        if (nav && this.menuOpened) {
          if (this.menuOpened) {
            const leave = this.$el.querySelectorAll('main')[0];
            leave.style.opacity = '0';
          }
        }

        timeline(sequence, {
          duration: 1,
          easing: [0.19, 1, 0.22, 1],
        }).finished.then(() => {
          this.$el.classList.remove('is-trans');
          if (this.menuOpened) this.$store.commit('TOGGLE_MENU');
          clearAllBodyScrollLocks();
          el.removeAttribute('style');
          el.children[fixed].removeAttribute('style');
          el.children[scroll].removeAttribute('style');
          if (this.top > 0) {
            window.scroll(0, this.top);
          }
          this.top = 0;
          done();
        });
      }
    },
    leave(el, done) {
      const fromHome = this.$store.state.route.from && this.$store.state.route.from.name && this.$store.state.route.from.name.includes('Index');

      if (fromHome) {
        el.style.position = 'fixed';
        el.style.top = '0px';
        el.style.left = '0px';

        this.$el.classList.remove('is-trans');
        this.$store.commit('SET_TRANSITION', false);
        clearAllBodyScrollLocks();
        done();
      } else {
        const enter = this.$el.querySelectorAll('main')[1];

        this.$el.classList.add('is-trans');
        const from = this.$store.state.route.from && this.$store.state.wp.pages.default[this.$store.state.route.from.params.slug] ? this.$store.state.wp.pages.default[this.$store.state.route.from.params.slug].acf.layout : false;
        const fixed = from === 'contact' ? 1 : 0;
        const scroll = from === 'contact' ? 0 : 1;

        el.style.position = 'absolute';

        const offset = window.pageYOffset * -1;
        window.scroll(0, 0);

        const { hash } = this.$route;
        if (hash) {
          const { top } = this.$el.querySelector(hash).getBoundingClientRect();
          this.top = top;
        }

        enter.style.top = `${this.top * -1}px`;

        const container = el.children[scroll];
        if (!this.$mq.isMobile) {
          container.style.transform = `translate3d(0, ${offset}px, 0)`;
        } else {
          el.style.transform = `translate3d(0, ${offset}px, 0)`;
        }
        el.style.zIndex = 5;

        const sequence = !this.$mq.isMobile ? [
          [
            el.children[fixed], {
              transform: 'translate3d(-100%, 0, 0)',
            }],
          [
            el.children[scroll], {
              transform: `translate3d(100%, ${offset}px, 0)`,
            }, { at: 0 }],
        ] : [
          [el, {
            transform: `translate3d(-100%, ${offset}px, 0)`,
          }],
        ];

        timeline(sequence, {
          duration: 1,
          easing: [0.19, 1, 0.22, 1],
        }).finished.then(() => {
          this.$el.classList.remove('is-trans');
          this.$store.commit('SET_TRANSITION', false);
          clearAllBodyScrollLocks();
          enter.removeAttribute('style');
          if (this.top > 0) {
            window.scroll(0, this.top);
          }
          this.top = 0;
          done();
        });
      }
    },
    toggleMenu() {
      this.$store.commit('TOGGLE_MENU');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.app-loaded {
  #loader {
    display: none;
  }
}

.page {
  position: relative;
  width: 100%;

  display: grid;
  align-items: start;
  min-height: calc(var(--vh) * 100);

  @include mq($until: m) {
    background: var(--white);
    padding-top: var(--header);
  }

  @include mq(m) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sheet {
    position: relative;
    background: var(--white);

    @include mq(m) {
      min-height: calc(var(--vh) * 100);
    }

    @include mq(m) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background: #E5E5E5;
        z-index: 2;
        pointer-events: none;
      }

      &:first-child {

        position: sticky;
        top: 0;

        &::after {
          // +box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
          right: -1px;
        }
      }

      &:last-child {
        &::after {
          left: 0;
          // +box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
        }
      }
    }

    .container {
      position: relative;
      padding: 0 var(--spacer-s);
      width: 100%;
    }

    &--fixed {
      margin-top: calc(75vmin - var(--header));
      padding-bottom: var(--spacer-s);

      @include mq(m) {
        margin-top: 0;
        padding-bottom: 0;

        position: relative;
        top: 0;
        height: calc(var(--vh) * 100);

        .container {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          figure {
            margin: 0 auto;
            @include mq(s) {
              max-width: 464px;
              max-height: 464px;
            }
          }
        }
      }
    }

    &--scroll {
      padding-bottom: var(--spacer-s);

      @include mq($until: m) {
        min-height: 75vmin;
      }

      /* @include mq(m) {
        grid-column: 2 / -1;
      } */
      .container {
        position: relative;

        @include mq(m) {
          margin-top: calc(var(--vh) * 50);
        }

        & > :first-child {
          margin-top: 0;
        }

        & > :last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.page--contact, &.page--404 {
    height: calc(var(--vh) * 100);
  }

  &.page--404 {
    align-content: center;

    .sheet {
      &:first-child {
        margin-top: 0;
        grid-row: 1;

        @include mq($until: m) {
          grid-row: 2;
          padding-bottom: 0;
        }
      }
      &:last-child {
        grid-row: 1;
      }
    }
  }

  &.page--contact {
    grid-template-columns: repeat(2, 1fr);

    @include mq($until: m) {
      padding-bottom: 36px;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      align-items: center;
      justify-items: center;
    }

    .sheet {
      @include mq(m) {
        &:first-child {
          &::after {
            left: 0;
          }
        }

        &:last-child {
          &::after {
            right: -1px;
          }
        }
      }

      &:first-child {
        height: 100%;
        margin-top: 0;
        grid-column-start: 2;
        grid-row: 1;

        @include mq($until: m) {
          grid-column-start: 1;
          grid-row: 2;
          padding-bottom: 0;
        }
      }
      &:last-child {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 1;

        @include mq(m) {
          padding-bottom: 0;
        }
      }
    }

    .sheet--fixed {
      * {
        height: 100%;
      }
    }

    .sheet--scroll {
      @include mq(m) {
        padding-bottom: 36px;
      }
    }

    .g {
      svg {
        height: 100%;
        width: 100%;
      }
      @include mq(m) {
        height: calc(var(--vh) * 100);
      }
    }

    .contact-links {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;

      @include mq(m) {
        position: absolute;
      }
    }
  }
}

.menu-btn {
  position: fixed;
  mix-blend-mode: difference;
  right: 0;
  color: var(--white);
  z-index: 15;
}

.is-trans {
  cursor: progress !important;

  * {
    cursor: progress !important;
    pointer-events: none !important;
  }
}
</style>
