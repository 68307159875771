/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Index from '@/views/Index.vue';
import Page from '@/views/Page.vue';
import Archive from '@/views/Archive.vue';

// import Single from '@/views/Single.vue';
import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';

const singles = new Map();

singles.set('lehre', Page);
singles.set('post', Page);
singles.set('research', Page);
singles.set('lecture', Index);

Vue.use(VueRouter);

const {
  show_on_front, page_on_front,
} = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

// let langs = null;

if (i18n) {
  store.commit('ADD_LANGUAGES', Object.keys(i18n.langs));
  // langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  // langs = langs.join('|');
} else {
  store.commit('ADD_LANGUAGES', ['default']);
}

const archiveSlugs = [];
Object.keys(postTypes).forEach((key) => {
  if ((key !== 'page' && key !== 'lecture' && key !== 'post' && key !== 'event') && postTypes[key].has_archive
  ) {
    archiveSlugs.push(postTypes[key].has_archive);
  }
});

const baseRoutes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: `/:slug(${archiveSlugs.join('|')})`,
    name: 'Archive',
    component: Archive,
    meta: {
      type: 'archive',
    },
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
  },
  {
    path: '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
  },
];

const routes = baseRoutes;

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'event') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name: `${singles.get(key).name}-${postTypes[key].label}`,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior() {
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  if (from.path !== to.path) {
    store.commit('SET_CURRENT_POST', null);
  }

  if (to.meta.customView || to.name === '404' || to.name === 'Preview') {
    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang ? to.params.lang : i18n ? i18n.default : 'default';

  store.commit('SET_LANG', lang);

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
  };

  const promises = [];

  if (request.type !== 'archive') {
    promises.push(store.dispatch('getSingleBySlug', request));
  } else {
    promises.push(store.dispatch('getItems', {
      type: request.slug,
      params: {
        lang: 'default',
      },
    }));
  }

  Promise.all(promises).then((res) => {
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    const page = res[0];
    if (page && slug) {
      if (to.path === '/') {
        document.title = `${siteTitle} — ${description}`;
      } else if (request.type === 'archive') {
        document.title = `${postTypes[request.slug].label} — ${siteTitle}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');
        p.innerHTML = page.title ? page.title.rendered : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace('_', '-');
      }

      next();
    } else {
      router.push({ name: '404' });
    }
  });
});

export default router;
