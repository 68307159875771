<template>
  <main
    :class="[
      'page',
      post && post.acf.layout
        ? `page--${post.acf.layout}`
        : $route.params.type !== 'page'
          ? 'page--single'
          : false,
    ]"
  >
    <div class="sheet sheet--fixed">
      <div class="container">
        <div class="t-center typo--xxl-container">
          <Figure
            v-if="post.acf.layout === 'img' && post.gds_featured_image"
            :data="{ ...post.gds_featured_image, ratio: true }"
          />
          <Spacer
            v-else-if="post.acf.layout === 'contact'"
            class="g"
            all="s"
          >
            <inline-svg :src="require('@/assets/svg/g.svg')" />
          </Spacer>
          <Title
            v-else
            :data="{ value: post.title.rendered, size: 'xxl' }"
          />
        </div>
      </div>
    </div>
    <div class="sheet sheet--scroll">
      <Gutenberg
        :data="post.gds_blocks"
        class="container"
      />

      <Flex
        v-if="post.acf.layout === 'contact' && post.slug === 'contact'"
        justify="space-between"
        class="contact-links"
      >
        <Cta
          v-if="
            options.links.privacy_policy && options.links.privacy_policy !== ''
          "
          :data="{
            title: 'Privacy policy',
            url: options.links.privacy_policy,
            target: '_blank',
            size: 'cta-small',
          }"
        />
        <Cta
          :data="{
            title: 'Website by Giga',
            url: 'https://www.gigadesignstudio.com',
            target: '_blank',
            size: 'cta-small',
          }"
        />
      </Flex>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Gutenberg from '@/components/blocks';

export default {
  name: 'Page',
  components: {
    Figure,
    Title,
    Cta,
    Gutenberg,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['options']),
  },
  mounted() {
    if (!this.$store.state.route.from.name && this.$route.hash) {
      this.$nextTick(() => {
        const { top } = this.$el
          .querySelector(this.$route.hash)
          .getBoundingClientRect();
        window.scroll(0, top);
      });
    }
  },
};
</script>
