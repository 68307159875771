<template>
  <transition
    name="cookie"
    appear
  >
    <div
      v-show="!cookie && init"
      class="cookie"
    >
      <Flex
        align="center"
        justify="space-between"
        column-gap="xxl"
      >
        <Richtext
          v-if="options.cookies.text"
          :data="{
            value: options.cookies.text,
            size: 'cookie'
          }"
        />
        <Flex
          column-gap="xxl"
          class="ctas"
        >
          <Cta
            :data="{
              title: 'Ok',
              fn: acceptCookie,
            }"
          />

          <Cta
            v-if="options.cookies.cta"
            :data="options.cookies.cta"
          />
        </Flex>
      </Flex>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript } from '@/assets/js/utils';

import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

// eslint-disable-next-line no-undef
const id = __VUE_WORDPRESS__.state.options?.cookies?.id;

export default {
  name: 'Cookie',
  components: {
    Richtext,
    Cta,
  },
  data() {
    return {
      init: true,
      cookie: !!Cookies.get(id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (!item.init) {
            if (item.inline) {
              const s = document.createElement('span');
              s.style.display = 'none';
              s.innerHTML = item.script;
              document.getElementsByTagName('body')[0].appendChild(s);
            } else {
              loadScript(item.script_src);
            }
          }
        });
      }

      if (this.$gtag) {
        this.$gtag.config(
          {
            params: {
              anonymize_ip: false,
              send_page_view: false,
            },
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-enter-active,
.cookie-leave-active {
  transition: all 0.8s $ease-custom;
}
.cookie-enter, .cookie-leave-to {
  transform: translateY(100%) translateY(30px);
}
.cookie {
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  width: 100%;

  background: var(--white);
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);

  .wysiwyg {
    padding: var(--spacer-s);
  }

  .cta {
    color: var(--red);
  }

  .ctas {
    flex: 0 0 100%;
    justify-content: space-between;

    @include mq(s) {
      justify-content: flex-start;
      flex: 0 0 auto;
    }
  }
}
</style>
