<template>
  <div
    :id="data.attrs.anchor"
    class="block--media"
  >
    <Figure :data="{ ...data.attrs.data, ratio: true, object_fit: 'contain' }" />
  </div>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'Media',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block--media {
  margin-top: var(--spacer-s);
  margin-bottom: var(--spacer-s);
  max-height: 464px;

  @include mq(s) {
    max-height: none;
  }
}
</style>
