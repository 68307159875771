import Vue from 'vue';
import Cookies from 'js-cookie';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import 'swiper/swiper.min.css';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/mq';
import '@/assets/js/layout';

// eslint-disable-next-line no-undef
const { url: baseUrl } = __VUE_WORDPRESS__.routing;
Vue.prototype.$relativeUrl = (url) => (url
  ? process.env.NODE_ENV === 'development' && process.env.VUE_APP_PROXY_TARGET ? url.replace(process.env.VUE_APP_PROXY_TARGET, '').replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
    : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
  : false
);

sync(store, router);
Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);

Vue.use(Vue2TouchEvents, {
  disableClick: true,
  touchHoldTolerance: 200,
});

// eslint-disable-next-line no-undef
const { options } = __VUE_WORDPRESS__.state;

if (options && options.extra && options.cookies && options.extra.ga_id && options.cookies.id && process.env.NODE_ENV !== 'development') {
  Vue.use(VueGtag, {
    config: {
      id: options.extra.ga_id,
      params: {
        anonymize_ip: !Cookies.get(options.cookies.id),
      },
    },
    disableScriptLoad: true,
    bootstrap: true,
  }, router);
}

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

// custom font
const font = new FontFace('CindieMono', `url(${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/font/cindie-mono.woff2)`, {
  style: 'normal',
  weight: '400',
});
document.fonts.add(font);
font.load();

Promise.all([document.fonts.ready, router.onReady]).then(() => {
  app.$mount('#app');
});
