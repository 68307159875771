<template>
  <main :class="['page', 'page--404']">
    <div class="sheet sheet--fixed">
      <div class="container">
        <div class="t-center typo--xxl-container">
          <Title
            :data="{ value: options['not-found'].title, size: 'xxl' }"
          />
          <Spacer
            use-margin
            top="s"
          >
            <Richtext
              :data="{ value: options['not-found'].text }"
            />
            <Cta
              :data="options['not-found'].link"
              theme="inline"
            />
          </Spacer>
        </div>
      </div>
    </div>
    <div class="sheet sheet--fixed">
      <div class="container">
        <div class="t-center typo--xxl-container">
          <Figure
            v-if="options['not-found'].image"
            :data="{ ...options['not-found'].image, ratio: true }"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'NotFound',
  components: {
    Title,
    Richtext,
    Cta,
    Figure,
  },
  computed: {
    ...mapGetters(['options']),
  },
};
</script>

<style lang="scss" scoped>
.page--404 {
  &::after {
    content: none;
  }

  .sheet {
    margin-top: 0;
  }
}
</style>
