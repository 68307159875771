var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{directives:[{name:"touch",rawName:"v-touch:end",value:(_vm.zoomOut),expression:"zoomOut",arg:"end"}],staticClass:"page--index"},[(_vm.lecture)?[_c('div',{ref:"gallery"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.zoomed),expression:"!zoomed"}],key:_vm.indexRow,staticClass:"slide-row"},_vm._l((_vm.gallery[_vm.indexRow]),function(slide,index){return _c('div',{directives:[{name:"touch",rawName:"v-touch:touchhold",value:(
            function () {
              !slide.custom ? _vm.zoomIn(slide) : null;
            }
          ),expression:"\n            () => {\n              !slide.custom ? zoomIn(slide) : null;\n            }\n          ",arg:"touchhold"}],key:index,class:[
            'slide',
            slide.custom
              ? 'slide--full slide--custom'
              : slide.acf.single && slide.acf.align === 'right'
                ? 'slide--right'
                : false ]},[(!slide.custom)?_c('Figure',{attrs:{"data":Object.assign({}, slide, {object_fit: 'contain'})}}):_c('div',[_c('inline-svg',{attrs:{"src":require(("@/assets/svg/" + (slide.custom) + ".svg"))}})],1)],1)}),0),(_vm.$mq.isMobile && _vm.indexRow > 0 && !_vm.single)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.zoomed),expression:"!zoomed"}],staticClass:"custom-mobile"},[(_vm.counter % 2 !== 0)?_c('inline-svg',{attrs:{"src":require('@/assets/svg/gestaltungslehre.svg')}}):_c('inline-svg',{attrs:{"src":require('@/assets/svg/and-design.svg')}})],1):_vm._e(),(_vm.zoomed)?_c('div',{staticClass:"gallery--zoom"},[_c('Figure',{attrs:{"data":Object.assign({}, _vm.slideZoomed, {object_fit: 'contain'})}})],1):_vm._e()]),_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(!_vm.zoomed),expression:"!zoomed"}],ref:"details",class:[
        'lecture-details',
        _vm.showDetails ? 'lecture-details--active' : false,
        !_vm.lecture.acf.download_lecture && !_vm.lecture.acf.download_notes
          ? 'lecture-details--active lecture-details--nolink'
          : false ],attrs:{"align":"baseline"}},[_c('div',{staticClass:"lecture-link",on:{"click":_vm.toggleDetails}},[(_vm.lecture.acf.link)?_c('Cta',{staticClass:"title",attrs:{"data":{
            title: _vm.lecture.title.rendered,
            url: _vm.lecture.acf.link.url,
          },"theme":"inherit"}}):_c('Title',{attrs:{"data":{ value: _vm.lecture.title.rendered }}}),_c('time',{domProps:{"innerHTML":_vm._s(_vm.lecture.acf.date)}})],1),(_vm.lecture.acf.download_lecture)?_c('Cta',{staticClass:"lecture-links",attrs:{"data":{
          title: 'Download lecture',
          url: _vm.lecture.acf.download_lecture,
          target: '_blank',
        }}}):_vm._e(),(_vm.lecture.acf.download_notes)?_c('Cta',{staticClass:"lecture-links",attrs:{"data":{
          title: 'Download notes',
          url: _vm.lecture.acf.download_notes,
          target: '_blank',
        }}}):_vm._e()],1),(_vm.lazyload.length > 0)?_c('div',{staticClass:"lazy-load"},[_c('div',{staticClass:"slide-row"},_vm._l((_vm.lazyload),function(img){return _c('div',{key:img.id,staticClass:"slide"},[_c('Figure',{attrs:{"data":Object.assign({}, img, {object_fit: 'contain'})}})],1)}),0)]):_vm._e()]:[_c('div',{ref:"gallery"},[_c('div',{key:_vm.indexRow,staticClass:"slide-row"},_vm._l((_vm.gallery[_vm.indexRow]),function(slide,index){return _c('div',{key:index,class:['slide', 'slide--full', 'slide--custom']},[_c('inline-svg',{attrs:{"src":require(("@/assets/svg/" + (slide.custom) + ".svg"))}})],1)}),0)]),(_vm.video)?_c('div',{staticClass:"video-embed-container"},[_c('div',{staticClass:"video-embed",domProps:{"innerHTML":_vm._s(_vm.video)}})]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }