<template>
  <div>
    <component
      :is="components.get(block.blockName)"
      v-for="(block, index) in data"
      :key="index"
      :data="block"
    />
  </div>
</template>

<script>
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Team from '@/components/blocks/team';
import Publication from '@/components/blocks/publication';
import Break from '@/components/blocks/break';
import Lectures from '@/components/blocks/lectures';
import Posts from '@/components/blocks/posts';

const components = new Map();

components.set('core/heading', Content);
components.set('core/paragraph', Content);
components.set('core/separator', Break);
components.set('core/image', Media);
components.set('core/list', Content);
components.set('acf/link', Content);
components.set('acf/team', Team);
components.set('acf/publication', Publication);
components.set('acf/lectures', Lectures);
components.set('acf/post', Posts);

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      components,
    };
  },
};
</script>

<style>

</style>
