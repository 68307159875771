<template>
  <header
    v-show="!$route.meta.hideHeader && !isZoomed"
    v-if="$route.name"
  >
    <Menu />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  computed: {
    ...mapGetters(['isZoomed']),
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
</style>
