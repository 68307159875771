<template>
  <main
    v-touch:end="zoomOut"
    class="page--index"
  >
    <template v-if="lecture">
      <div ref="gallery">
        <div
          v-show="!zoomed"
          :key="indexRow"
          class="slide-row"
        >
          <div
            v-for="(slide, index) in gallery[indexRow]"
            :key="index"
            v-touch:touchhold="
              () => {
                !slide.custom ? zoomIn(slide) : null;
              }
            "
            :class="[
              'slide',
              slide.custom
                ? 'slide--full slide--custom'
                : slide.acf.single && slide.acf.align === 'right'
                  ? 'slide--right'
                  : false,
            ]"
          >
            <Figure
              v-if="!slide.custom"
              :data="{ ...slide, object_fit: 'contain' }"
            />

            <div v-else>
              <inline-svg :src="require(`@/assets/svg/${slide.custom}.svg`)" />
            </div>
          </div>
        </div>

        <div
          v-if="$mq.isMobile && indexRow > 0 && !single"
          v-show="!zoomed"
          class="custom-mobile"
        >
          <inline-svg
            v-if="counter % 2 !== 0"
            :src="require('@/assets/svg/gestaltungslehre.svg')"
          />
          <inline-svg
            v-else
            :src="require('@/assets/svg/and-design.svg')"
          />
        </div>

        <div
          v-if="zoomed"
          class="gallery--zoom"
        >
          <Figure :data="{ ...slideZoomed, object_fit: 'contain' }" />
        </div>
      </div>

      <Flex
        v-show="!zoomed"
        ref="details"
        :class="[
          'lecture-details',
          showDetails ? 'lecture-details--active' : false,
          !lecture.acf.download_lecture && !lecture.acf.download_notes
            ? 'lecture-details--active lecture-details--nolink'
            : false,
        ]"
        align="baseline"
      >
        <div
          class="lecture-link"
          @click="toggleDetails"
        >
          <Cta
            v-if="lecture.acf.link"
            :data="{
              title: lecture.title.rendered,
              url: lecture.acf.link.url,
            }"
            class="title"
            theme="inherit"
          />
          <Title
            v-else
            :data="{ value: lecture.title.rendered }"
          />
          <time v-html="lecture.acf.date" />
        </div>
        <Cta
          v-if="lecture.acf.download_lecture"
          :data="{
            title: 'Download lecture',
            url: lecture.acf.download_lecture,
            target: '_blank',
          }"
          class="lecture-links"
        />
        <Cta
          v-if="lecture.acf.download_notes"
          :data="{
            title: 'Download notes',
            url: lecture.acf.download_notes,
            target: '_blank',
          }"
          class="lecture-links"
        />
      </Flex>

      <div
        v-if="lazyload.length > 0"
        class="lazy-load"
      >
        <div class="slide-row">
          <div
            v-for="img in lazyload"
            :key="img.id"
            class="slide"
          >
            <Figure :data="{ ...img, object_fit: 'contain' }" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div ref="gallery">
        <div
          :key="indexRow"
          class="slide-row"
        >
          <div
            v-for="(slide, index) in gallery[indexRow]"
            :key="index"
            :class="['slide', 'slide--full', 'slide--custom']"
          >
            <inline-svg :src="require(`@/assets/svg/${slide.custom}.svg`)" />
          </div>
        </div>
      </div>

      <div
        v-if="video"
        class="video-embed-container"
      >
        <div
          class="video-embed"
          v-html="video"
        />
      </div>
    </template>
  </main>
</template>

<script>
import data from '@/mixins/data';

import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'Index',
  components: {
    Title,
    Cta,
    Figure,
  },
  mixins: [data],
  data() {
    return {
      carousel: null,
      gallery: [],
      indexRow: 0,
      counter: 0,
      timer: null,
      showDetails: false,
      zoomed: false,
      slideZoomed: null,
      lazyload: [],
      single: null,
      lecture: null,
      video: null,
    };
  },
  watch: {
    $mq: {
      deep: true,
      handler() {
        this.indexRow = 0;
        this.counter = 0;
        if (this.lecture) {
          this.gallery = this.setGallery(this.lecture.acf.gallery);
        }
      },
    },
  },
  created() {
    this.lecture = this.post.type === 'lecture' ? this.post : this.post.acf.lecture;
    if (this.lecture) {
      this.single = this.post.type === 'lecture';
      if (this.lecture.acf.gallery) {
        this.gallery = this.setGallery(this.lecture.acf.gallery);
      }
    } else if (this.post.slug === 'index') {
      this.gallery = [
        [
          {
            custom: 'gestaltungslehre',
            first: true,
          },
        ],
        [
          {
            custom: 'and-design',
          },
        ],
      ];
      this.video = this.post.acf.video;
    }
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    setGallery(gallery) {
      const array = [];
      if (!this.single) {
        array.push([
          {
            custom: 'gestaltungslehre',
            first: true,
          },
        ]);
        array.push([
          {
            custom: 'and-design',
          },
        ]);
      }
      gallery.forEach((item) => {
        if (item.acf?.single) {
          array.push([item]);
        } else if (
          array[array.length - 1]
          && array[array.length - 1].length < 2
          && !array[array.length - 1][0].custom
          && !array[array.length - 1][0].acf?.single
        ) {
          array[array.length - 1].push(item);
        } else {
          array.push([item]);
        }
      });

      return this.$mq.isMobile
        ? array.filter(
          (row) => row.filter((item) => item.custom && !item.first).length === 0,
        )
        : array;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.lecture) {
          if (this.counter < this.gallery.length - 1) {
            this.lazyload = this.lecture.acf.gallery.filter(
              (el, i) => i <= this.indexRow * 5 + 4,
            );
          } else {
            this.lazyload = [];
          }
        }

        if (this.indexRow < this.gallery.length - 1) {
          this.indexRow += 1;
        } else {
          this.indexRow = 0;
        }
        this.counter += 1;
      }, 1000);
    },
    toggleDetails() {
      if (
        !this.lecture.acf.download_lecture
        && !this.lecture.acf.download_notes
      ) return;
      this.showDetails = !this.showDetails;
      if (this.showDetails) {
        document.body.addEventListener('click', this.closeDetails);
      } else {
        document.body.removeEventListener('click', this.closeDetails);
      }
    },
    closeDetails(e) {
      const ref = this.$refs.details.$el;
      if (!ref.contains(e.target) && e.target !== ref) {
        this.showDetails = false;
        document.body.removeEventListener('click', this.closeDetails);
      }
    },
    zoomIn(slide) {
      this.$store.commit('IS_ZOOMED', true);
      this.zoomed = true;
      this.slideZoomed = slide;
      clearInterval(this.timer);
    },
    zoomOut() {
      if (this.zoomed) {
        this.$store.commit('IS_ZOOMED', false);
        this.startTimer();
        this.slideZoomed = null;
        this.zoomed = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page--index {
  position: relative;
  height: calc(var(--vh) * 100);
  width: 100%;
  background: var(--white);
  overflow: hidden;
  user-select: none;

  .lecture-details {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between;

    transform: translate3d(0, 38px, 0);
    transition: transform 0.3s $ease-custom;

    @include mq(m) {
      padding-left: var(--spacer-s);
      width: 50vw;
      transition: none;
      transform: none;
    }

    &--active {
      transform: translate3d(0, 0px, 0);
    }

    &--nolink {
      transform: translate3d(0, 0px, 0);
      justify-content: center;
    }

    .event-links {
      @include mq($until: m) {
        flex-basis: 100%;
        text-align: center;
      }
    }

    .lecture-link {
      @extend %typo--cta;
      text-transform: none;
      flex-basis: 100%;
      text-align: center;
      padding: var(--spacer-s);

      @include mq(m) {
        text-align: left;
        padding: var(--spacer-s) 0;
      }

      .title {
        display: inline;
      }

      time {
        display: inline;
        margin-left: var(--spacer-xs);
      }

      @include mq(m) {
        justify-content: flex-start;
        flex-basis: auto;
      }
    }

    .cta,
    .title {
      color: var(--red);

      @include mq($until: m) {
        text-transform: none;
      }
    }
  }

  .gallery--zoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding: var(--spacer-s);
  }

  .slide {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: var(--spacer-s);

    @include mq(m) {
      padding: 0 var(--spacer-xxl);
    }

    figure {
      max-width: 270px;
      max-height: 210px;

      @include mq(s) {
        max-width: 464px;
        max-height: 464px;
      }
    }

    &-row {
      height: calc(var(--vh) * 100);
      padding: var(--header) 0;
      display: grid;

      grid-template-rows: repeat(2, minmax(0, 1fr));
      row-gap: 35px;

      @include mq(m) {
        row-gap: 0px;
        column-gap: var(--spacer-m);
        grid-template-rows: auto;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    &--right {
      grid-row-start: 2;
      @include mq(m) {
        grid-row-start: auto;
        grid-column-start: 2;
      }
    }

    &--full {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      display: flex;
      align-items: center;
      padding: 0;
    }

    &--custom {
      div {
        padding: 0 var(--spacer-s);
        width: 100%;
      }
      svg {
        width: 100%;
      }
    }
  }

  .custom-mobile {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    z-index: 2;
    padding: 0 var(--spacer-s);
    line-height: 0;
  }
}

.lazy-load {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  .slide {
    grid-column: 1 / 1;
    grid-row: 1 / -1;
  }
}
</style>

<style lang="scss">
.video-embed-container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 1000px;
  width: 90vw;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);

  .video-embed {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
